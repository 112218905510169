import { PricingContainer, H2 } from "./PricingElements";
import { PricingCardSection, QRCodesSection } from "../PricingCardSection";

import BTC_QR_CODE from "../../images/PaymentQRCodes/BTC.png";
import ETH_QR_CODE from "../../images/PaymentQRCodes/ETH.png";
import CASH_APP_QR_CODE from "../../images/PaymentQRCodes/CashAppCROPv2.png";
import VENMO_QR_CODE from "../../images/PaymentQRCodes/VenmoCROP.png";
import STRIPE_LOGO from "../../images/stripe-logo-square.png";

const Pricing = () => {
  return (
    <PricingContainer>
      <H2>Pricing</H2>
      <PricingCardSection>
        <div className="cards_wrap">
          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Initial Consultation</h1>
              <h3 className="subtitle">{"FREE"}</h3>
              <ul>
                <li>- in person or virtual</li>
                <li>- up to 1 hour per meeting</li>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="appointment-button"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_CALENDARING_LINK;
                  }}
                >
                  Book an appointment
                </button>
              </div>
            </div>
          </div>
          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Incubator</h1>
              <h3 className="subtitle">
                Pricing determined after 1 free consultation
              </h3>
              <ul>
                <li>- 50% advance and remainder paid bi-weekly 1st/15th</li>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="appointment-button"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_CALENDARING_LINK;
                  }}
                >
                  Book an appointment
                </button>
              </div>
            </div>
          </div>
          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Hourly Consultations</h1>
              <h3 className="subtitle">
                starting at <small>$</small>
                {"199/hr"}
              </h3>
              <ul>
                <li>- In person optional or remote</li>
                <li>- 100% satisfaction guaranteed or your money back</li>
                <li>
                  - Invoices sent on the 1st/15th OR after each session for
                  ad-hoc work
                </li>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="appointment-button"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_CALENDARING_LINK;
                  }}
                >
                  Book an appointment
                </button>
              </div>
            </div>
          </div>
          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Month-to-Month</h1>
              <h3 className="subtitle">
                Pricing determined after 1 free consultation
              </h3>
              <ul>
                <li>- 100% satisfaction guaranteed or your money back</li>
                <li>- Invoices sent on the 1st/15th</li>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="appointment-button"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_CALENDARING_LINK;
                  }}
                >
                  Book an appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      </PricingCardSection>
      <H2>We Accept</H2>
      <QRCodesSection>
        <div className="cards_wrap">
          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">BTC</h1>
              <img src={BTC_QR_CODE} alt="BTC" width={360} height={360}></img>
            </div>
          </div>

          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">ETH</h1>
              <img src={ETH_QR_CODE} alt="ETH" width={360} height={360}></img>
            </div>
          </div>

          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Cash App</h1>
              <h3 className="subtitle"></h3>
              <img
                src={CASH_APP_QR_CODE}
                alt="Cash App"
                width={346}
                height={345}
              ></img>
            </div>
          </div>

          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Venmo</h1>
              <h3 className="subtitle"></h3>
              <img
                src={VENMO_QR_CODE}
                alt="Venmo"
                width={303}
                height={306}
              ></img>
            </div>
          </div>

          <div className="card_item">
            <div className="card_inner">
              <h1 className="title"></h1>
              <img
                src={STRIPE_LOGO}
                alt="Stripe"
                width={360}
                height={360}
              ></img>
              <h3 className="subtitle">
                Invoices will be sent to your email to pay by credit card
              </h3>
            </div>
          </div>

          <div className="card_item">
            <div className="card_inner">
              <h1 className="title">Wire Transfers</h1>
              <p className="subtitle">
                Email us for our routing and account numbers
              </p>
            </div>
          </div>
        </div>
      </QRCodesSection>
    </PricingContainer>
  );
};

export default Pricing;
